<script setup>
const emit = defineEmits()

const PopMansePrintBox = ref(true)
const close = () => {
  emit('close')
}
function open() {
  alert(PopMansePrintBox.value)
  PopMansePrintBox.value = true
}
const printFrame = ref(null)

function printDiv() {
  const printWindow = printFrame.value.contentWindow || printFrame.value.contentDocument.defaultView
  printWindow.print()
}
</script>
<template>
  <VDialog
    v-model="PopMansePrintBox"
    width="740px"
    max-width="100%"
    class="dialog-st1"
    :style="{ overflow: 'hidden' }"
  >
    <VCard>
      <div class="pop-inner" style="height: 600px">
        <div class="pop-tit-box flex-sb pop-right-close">
          <div class="">사주 결과 미리보기</div>
          <div class="com-btn-box">
            <button class="sBtn h26" type="button" @click="printDiv">출력하기</button>
          </div>
          <DialogCloseBtn variant="text" size="default" @click="close" />
        </div>
        <div class="pop-body h-100 pd-top-none">
          <div class="print-wrap" style="height: 500px">
            <iframe
              ref="printFrame"
              name="printDiv"
              src="/manse/print"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen
              style="overflow: hidden; display: block; width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
<style>
.dialog-content {
  max-height: 500px; /* 원하는 최대 높이 설정 */
  overflow: hidden; /* 스크롤바 숨기기 */
}
</style>
