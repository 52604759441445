<!-- src/pages/MyPage.vue -->
<script setup>
import { cloneDeep } from 'lodash'
import ManseBirthMain from '@/pages/manse/components/ManseBirthMain.vue'
import ManseDaeunMain from '@/pages/manse/components/ManseDaeunMain.vue'
import ManseCard from '@/pages/manse/components/ManseCard.vue'

import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import {
  groupListOpen,
  isPopSajuGroupChange,
  closeGroupChange,
  openGroupSetting,
  isPopSajuGroupSetting,
  closeGroupSetting,
} from '@/composables/useGroup'

import { fastSearch, yearMonth, userGroups, userGroupDel, userGroupSave2 } from '@/api/Eoway'
import { getDeatil, updateGroup } from '@/api/UserGroup'
import { saveMemo } from '@/api/UserGroup'

import { useRoute } from 'vue-router'
import useAuth from '@/composables/useAuth'

//popup
import PopSajuGroupChange from '@/pages/saju/components/PopSajuGroupChange.vue'
import PopSajuGroupSetting from '@/pages/saju/components/PopSajuGroupSetting.vue'
import PopGanji from '@/pages/manse/components/PopGanji.vue'
import PopArea from '@/pages/manse/components/PopArea.vue'
import ComLatest from '@/pages/manse/components/ComLatest.vue'
import PopMansePrint from '@/pages/manse/components/PopMansePrint.vue'
import PopMemoReg from '@/pages/saju/components/PopMemoReg.vue'
import PopMemoList from '@/pages/saju/components/PopMemoList.vue'
import PopNameComponent from '@/pages/manse/components/PopName.vue'

//Alert
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'

definePage({ meta: { layout: 'manse' } })

const route = useRoute()
const router = useRouter()
const { userInfo } = useAuth()

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()

const isLoading = ref(false)
const colorMode = ref('fntcolor01')

const rsDataNor = ref(false)

const isOptionInput = ref(false),
  rsData01 = ref(false),
  isCheckboxChecked = ref(false)

const formData = ref({
  year: '',
  month: '',
  day: '',
  hour: '',
  min: '',
  gender: null,
  userName: '',
  isLunar: false,
  lunar: null,
  isLeapYear: null,
  bornAreaId: null,
  birth_time: null,
  birth: null,
  birthTime: null,
  option1: true,
  option2: true,
  option3: false,
  option4: true,
  addressTxt: null,
  lang: 'KOR',
  langYN: 'N',
  userGroupId: null,
})

const rsDataAll = ref({})
const langMode = ref('CHNKOR')
const sortMode = ref('desc')

const transdata = ref({})
const inputdata = ref({})
const birthData = ref({})
const curDaeun = ref({})
const daeunsData = ref({})
const selectDaeun = ref({})
const birthJijanggan = ref({})
const birthTitle = ref('시일월년')
const gongDataVal = ref(null)
const isDaeunCheck = ref(false)
const birthFiveCnt = ref({})

const hapDataValEarth = ref(null)
const hapDataVal = ref(null)

const yearsData = ref({})
const monthsData = ref({})
const isDaeunYear = ref(true)
const isDaeunMonth = ref(true)
const daeunCurNum = ref(0)
const daeunYearCurNum = ref(0)
const daeunMonthCurNum = ref(0)
const seunIndex = ref(0)

const isPopGanjiBox = ref(false)
const isPopAreaBox = ref(false)

const selectedGroup = ref({})
const userGroupId = ref(null)
const userGroupName = ref('그룹미지정')

const goList = () => {
  router.push('/saju')
}

watch(
  () => [route.params],
  async (newParams) => {
    // 콜백 함수를 async로 정의
    const [params] = newParams
    if (typeof params.id === 'undefined') {
      await router.push('/blank?path=/manse') // 비동기 처리
    } else {
      id.value = params?.id
      pgmode.value = params?.pgmode

      if (params.pgmode) {
        try {
          await getParams()
          await searchEoway()
        } catch (error) {
          alert('getParams : ' + error.response?.data?.message)
        }
      }
    }
  },
)

const handleLeapYearChange = () => {
  if (formData.value.isLeapYear) {
    formData.value.isLunar = null
  } else {
    formData.value.isLunar = false
  }
}

const handleLeapLunarChange = () => {
  formData.value.isLeapYear = null
}

const searchEowayDo = () => {
  formData.value.langYN = 'N'
  searchEoway()
}

const oldFormData = ref({})
const searchEoway = async () => {
  if (areaTxt.value === null) {
    formData.value.addressTxt = areaTxt.value
    formData.value.bornAreaId = null
  }

  let formValidate = true
  if (formData.value.gender === null) {
    showDialogAlert('성별을 선택해 주세요.')
    //alert('성별을 선택해 주세요.');
    formValidate = false
    return
  }
  if (
    formData.value.isLunar === null &&
    (formData.value.isLeapYear === null || formData.value.isLeapYear === false)
  ) {
    showDialogAlert('양력, 음력, 윤달을 선택해 주세요.')
    formValidate = false
    return
  }
  if (formData.value.year === '' || formData.value.month === '' || formData.value.day === '') {
    showDialogAlert('생년월일을 입력해주세요.')
    formValidate = false
    return
  }
  if (
    (formData.value.hour === '' || formData.value.min === '') &&
    formData.value.birth_time !== true
  ) {
    showDialogAlert('출생시간을 입력해주세요.')
    formValidate = false
    return
  }

  if (pgmode.value !== 'rs') {
    formValidate = useFun.checkDateTimeValid({
      checkType: 'date',
      inputYear: formData.value.year,
      inputMonth: formData.value.month,
      inputDay: formData.value.day,
    })
    if (!formValidate) {
      showDialogAlert('생년월일을 올바르게 입력해주세요.')
      formValidate = false
      return
    }
    formValidate = useFun.checkDateTimeValid({
      checkType: 'time',
      inputHour: formData.value.hour,
      inputMinute: formData.value.min,
    })
    if (!formValidate) {
      showDialogAlert('출생시간을 올바르게 입력해주세요.')
      return
    }
  }
  if (!formValidate) return
  try {
    isLoading.value = true
    const { month, day, hour, min } = formData.value

    formData.value.birth = `${formData.value.year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`
    formData.value.birthTime = `${hour.toString().padStart(2, '0')}:${min
      .toString()
      .padStart(2, '0')}`

    // 시간모름 일때
    if (formData.value.birth_time) {
      formData.value.hour = '--'
      formData.value.min = '--'
      formData.value.birthTime = '25:00'
    }

    //지역값 없을 시 삭제하고 보내기
    if (formData.value.bornAreaId === null) {
      delete formData.value.bornAreaId
      delete oldFormData.value.bornAreaId
    }

    const params = reactive({}) // reactive로 초기화
    if (formData.value.langYN === 'Y') {
      Object.assign(params, oldFormData.value) // oldFormData의 값을 params에 할당
    } else {
      Object.assign(params, formData.value) // formData의 값을 params에 할당
    }

    const data = await fastSearch(params) // 저장목록 조회
    if (data.errmsg?.code === 1) {
      showDialogAlert(data.errmsg.msg)
      return
    }
    rsDataAll.value = data
    transdata.value = data.transdata
    inputdata.value = data.inputdata
    birthData.value = data.birthganji
    daeunsData.value = data.daeuns
    birthJijanggan.value = data.jijanggan
    rsData01.value = true

    yearsData.value = data.years
    monthsData.value = data.months

    daeunCurNum.value = data.transdata.curlucknum
    if (daeunCurNum.value > 100) {
      daeunCurNum.value = Math.max(...data.daeuns.sky.map((da) => da.num))
    }
    curDaeun.value.sky = data.daeuns.sky.filter((da) => da.num === daeunCurNum.value)
    curDaeun.value.earth = data.daeuns.earth.filter((da) => da.num === daeunCurNum.value)

    hapDataVal.value = five.convertHap(data?.hap, false).join('ㆍ')
    hapDataValEarth.value = five.convertHap(data?.hap, true).join('ㆍ')

    //대운 반대로
    daeunsData.value.sky = reverseData(daeunsData.value.sky)
    daeunsData.value.earth = reverseData(daeunsData.value.earth)
    monthsData.value.sky = reverseData(monthsData.value.sky)
    monthsData.value.earth = reverseData(monthsData.value.earth)
    yearsData.value.sky = reverseData(yearsData.value.sky)
    yearsData.value.earth = reverseData(yearsData.value.earth)

    gongDataVal.value = useFun.convertGongmangTaewol(data)

    // 오행구성 수정
    birthFiveCnt.value = five.fiveColorCnt(curDaeun.value, data.birthganji?.five)

    //갤러리닫기
    rsDataNor.value = false
    if (pgmode.value === 'rs') rsDataNor.value = true
    //if (langMode.value) rsDataNor.value = true;

    //기존 데이터 저장해 두기
    oldFormData.value = cloneDeep(formData.value)
    //oldFormData.value = formData.value;
  } catch (error) {
    console.error('Failed to load users:', error)
  } finally {
    //rsData01.value =false;
    isLoading.value = false
  }
}
const ManseBirthMainArea = ref(null)
const getDaeun = (typ, item, curSelectedDaeun = {}) => {
  if (typ === 'month') {
    daeunMonthCurNum.value = item.num
  }
  if (typ === 'daeun') getSeun(item)
  if (typ === 'seun') getMonth(item)
  if (typ === 'daeun') {
    curDaeun.value.sky = curSelectedDaeun.sky.filter((da) => da.num === item.num)
    curDaeun.value.earth = curSelectedDaeun.earth.filter((da) => da.num === item.num)
    // 오행구성데이터 수정
    birthFiveCnt.value = five.fiveColorCnt(curDaeun.value, birthData.value?.five)

    ManseBirthMainArea.value.setCurDaeun(curDaeun.value)
  }
}
const getSeun = async (item) => {
  daeunCurNum.value = item?.num
  const formParams = {}
  selectDaeun.value = item // 배열이 0 부터 시작이어서 -1 로 수정
  formParams.birthYear = inputdata.value.year.toString()
  formParams.daun = daeunCurNum.value.toString()
  formParams.hanja = birthData.value?.sky?.day?.value
  formParams.seun = 0
  formParams.lang = formData.value.lang
  isDaeunYear.value = true
  const result = await yearMonth(formParams)
  yearsData.value = result.years
  // 세운 호출
  getMonth(result.years.sky[0])
  yearsData.value.sky = reverseData(yearsData.value.sky)
  yearsData.value.earth = reverseData(yearsData.value.earth)
  daeunYearCurNum.value = yearsData.value.sky[yearsData.value.sky.length - 1].num
}
const getMonth = async (item) => {
  seunIndex.value = item?.num
  const formParams = {}
  formParams.birthYear = inputdata.value.year
  formParams.daun = `${daeunCurNum.value}`
  formParams.hanja = birthData.value?.sky?.day?.value
  formParams.seun = `${item?.num}`
  formParams.lang = formData.value.lang
  isDaeunMonth.value = true
  daeunYearCurNum.value = item?.num
  const result = await yearMonth(formParams)
  monthsData.value = result.months
  monthsData.value.sky = reverseData(monthsData.value.sky)
  monthsData.value.earth = reverseData(monthsData.value.earth)
  daeunMonthCurNum.value = monthsData.value.sky[monthsData.value.sky.length - 1].num
}

/* 순서변경 */
const sortChange = () => {
  sortMode.value = sortMode.value === 'desc' ? 'asc' : 'desc'
  birthTitle.value = birthTitle.value.split('').reverse().join('')
  reverseDaeunData()
}

const reverseDaeunData = () => {
  daeunsData.value.sky = reverseData(daeunsData.value.sky)
  daeunsData.value.earth = reverseData(daeunsData.value.earth)
  monthsData.value.sky = reverseData(monthsData.value.sky)
  monthsData.value.earth = reverseData(monthsData.value.earth)
  yearsData.value.sky = reverseData(yearsData.value.sky)
  yearsData.value.earth = reverseData(yearsData.value.earth)

  const currYear = new Date().getFullYear()
  const foundYearData = yearsData.value.sky.find((item) => item.num === currYear)

  if (foundYearData) {
    daeunYearCurNum.value = foundYearData.num
  } else {
    daeunYearCurNum.value = yearsData.value.sky[yearsData.value.sky.length - 1].num
  }

  const foundMonthData = monthsData.value.sky.find((item) => item.num === new Date().getMonth() + 1)

  if (foundMonthData && daeunYearCurNum.value === currYear) {
    daeunMonthCurNum.value = foundMonthData.num
  } else {
    daeunMonthCurNum.value = monthsData.value.sky[monthsData.value.sky.length - 1].num
  }

  rsDataAll.daeuns.sky = daeunsData.sky
  rsDataAll.daeuns.earth = daeunsData.earth
}
const reverseData = (data) => {
  return Object.values(data).reverse()
}

/*글자 및 색상변경*/
const searchEowayTrans = (mode) => {
  langMode.value = mode
  if (mode === 'CHNKOR') {
    formData.value.lang = 'KOR'
    oldFormData.value.lang = 'KOR'
  } else {
    formData.value.lang = mode
    oldFormData.value.lang = mode
  }
  formData.value.langYN = 'Y'
  searchEoway()
}
const searchEowayTransColor = (mode) => {
  colorMode.value = mode
}

const id = ref(null)
const pgmode = ref(null)

/*갤러리*/
const handleDefaultReport = () => {
  rsDataNor.value = !rsDataNor.value
  let scrollTopVal = 1300
  if (pgmode.value === 'rs') {
    scrollTopVal = 1100
  }
  if (rsDataNor.value) {
    setTimeout(() => {
      const currentScrollY = window.scrollY
      window.scrollTo({
        top: currentScrollY + scrollTopVal,
        behavior: 'smooth',
      })
    }, 1000)
  }
}

onMounted(async () => {
  id.value = route.params?.id
  pgmode.value = route.params?.pgmode

  // API 조회
  if (pgmode.value) {
    try {
      await getParams()
      await searchEoway()
    } catch (error) {
      showDialogAlert('getParams : ' + error.response?.data?.message)
    }
  }
})
const memoContent = ref('')
const memoCount = ref(0)
async function getParams() {
  try {
    const result = await getDeatil(id.value)

    const { integrationBirth } = result
    //const dateTime = integrationBirth.split(''); // ["1999-01-01", "10:10:00"]
    const dateTime = integrationBirth.split('T') // ["1999-01-01", "10:10:00"]
    const [year, month, day] = dateTime[0].split('-') // ["1999", "01", "01"]
    const [hour, min] = dateTime[1]?.split(':') // ["10", "10"]

    formData.value.year = year // "1999"
    formData.value.month = month.padStart(2, '0') // "01"
    formData.value.day = day.padStart(2, '0') // "01"
    formData.value.hour = hour.padStart(2, '0') // "10"
    formData.value.min = min.padStart(2, '0') // "10"
    formData.value.userName = result.guestUserName
    // 선택된 그룹정보 저장
    selectedGroup.value = result
    userGroupId.value = result.userGroup?.userGroupId
    userGroupName.value = result.userGroup?.name || '그룹미지정'

    memoCount.value = result.memoCount
    //selectGender.value = result.gender === 'MEN' ? 1 : 0;
    formData.value.gender = result.gender === 'MEN' ? 1 : 0
    formData.value.isLunar = result.isLuna === null ? false : result.isLuna
    formData.value.isLeapYear = result.isLeapYear
    formData.value.addressTxt = result.bornArea
    formData.value.bornAreaId = `${result.bornAreaId}`
    formData.value.option1 = result.option1
    formData.value.option2 = result.option2
    formData.value.option3 = result.option3
    formData.value.option4 = result.option4
    formData.value.option5 = result.option5
  } catch (error) {
    console.log(error)
    // 에러 처리
    throw error // 에러가 발생하면 다시 throw
  }
}
//저장하기(로그인체크)
async function regSave() {
  if (userInfo === null || typeof userInfo?.name === 'undefined') {
    showDialogAlert('로그인 후 이용 가능 합니다.')
    return
  }

  showDialogConfirm(
    '저장하시겠습니까?',
    '저장목록에서 확인 가능합니다.',
    '저장',
    await getUserGroups,
  )
  /*
  // eslint-disable-next-line no-restricted-globals
  if (confirm('저장하시겠습니까? \n저장목록에서 확인 가능합니다.')) {
    // 00> groupId 가져오기
    await getUserGroups()
    //await save();
  }
    */
}

function handleBirthTime() {
  if (formData.value.birth_time) {
    formData.value.hour = '--'
    formData.value.min = '--'
    isCheckboxChecked.value = true
  } else {
    formData.value.hour = ''
    formData.value.min = ''
    isCheckboxChecked.value = false
  }
}

//그룹정보 조회
async function getUserGroups() {
  if (userGroupId.value) {
    formData.value.userGroup = { userGroupId: userGroupId.value }
  } else {
    const data = await userGroups({})
    formData.value.userGroupId = data?.find((item) => item.isFixed)?.userGroupId
    formData.value.guestUserName = formData.value.userName
    formData.value.userGroup = { userGroupId: formData.value.userGroupId }
  }
  await save()
}

//저장하기
async function save() {
  const { month, day, hour, min } = formData.value

  formData.value.birth = `${formData.value.year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
  formData.value.birthTime = `${hour.toString().padStart(2, '0')}:${min
    .toString()
    .padStart(2, '0')}`
  formData.value.lunar = transdata.value.lunar

  const params = reactive({ ...formData.value })
  if (formData.value.gender === 0 || formData.value.gender === '0') {
    params.gender = 'WOMEN'
  } else {
    params.gender = 'MEN'
  }

  params.sajugangi = `${birthData.value.sky.hour.value}${birthData.value.sky.day.value}${birthData.value.sky.month.value}${birthData.value.sky.year.value}`
  params.sajugangi += `${birthData.value.earth.hour.value}${birthData.value.earth.day.value}${birthData.value.earth.month.value}${birthData.value.earth.year.value}`
  try {
    const result = await userGroupSave2(params)
    //메모가 있을 경우 저장하기
    if (memoContent.value) {
      const param = {}
      const paramUser = {}
      paramUser.guestUserId = result.guestUserId
      param.guestUser = paramUser
      param.content = memoContent.value
      saveMemo(param)
        .then(() => {
          showDialogAlert('저장 되었습니다.')
        })
        .catch((error) => {
          showDialogAlert(error.response.data?.message)
        })
    } else {
      showDialogAlert('저장 되었습니다.')
    }
  } catch (error) {
    showDialogAlert('에러 발생:', error)
    console.error('에러 발생:', error)
  }
}

//그룹선택저장
async function changeGroup(selectedGroup) {
  userGroupId.value = selectedGroup[0]?.userGroupId
  userGroupName.value = selectedGroup[0]?.name
  if (pgmode.value === 'rs') {
    try {
      const result = await getDeatil(id.value)
      let selectUserGroup = result
      if (!selectUserGroup.userGroup) {
        selectUserGroup.userGroup = {} // 빈 객체로 초기화
      }
      selectUserGroup.userGroup.userGroupId = userGroupId.value
      const upResult = await updateGroup(selectUserGroup)
      selectedGroup.value = selectUserGroup
    } catch (error) {
      showDialogAlert(error.message || 'Error occurred while fetching details.') // 에러 메시지 표시
    }
  }
  isPopSajuGroupChange.value = false
}

//삭제
async function delComLast() {
  const param = {
    guestUsersIds: id.value,
  }

  // eslint-disable-next-line no-restricted-globals
  if (confirm('삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다.')) {
    await userGroupDel(param)
    router.push('/saju')
  }
}

//정보 조회 값 세팅
const searchInput = (item) => {
  Object.assign(formData.value, item)
  const timeParts = item.time.split(':') // 시간을 ":"를 기준으로 분리
  const hour = timeParts[0] // 시간 부분을 가져옴
  const minute = timeParts[1] // 분 부분을 가져옴

  formData.value.hour = hour // 시간을 formData의 속성에 할당
  formData.value.min = minute // 분을 formData의 속성에 할당
}

// 지역검색시
const handleFocusArea = () => {
  formData.value.option4 = true
  if (!isPopAreaBox.value) {
    isPopAreaBox.value = true
  }
}

const areaTxt = ref('')
//지역선택
const selectAreaFinish = (selectAreaItem) => {
  areaTxt.value = `${selectAreaItem.value.korCountryName}, ${selectAreaItem.value.korCityName} (${selectAreaItem.value.longitudeType} ${selectAreaItem.value.degrees}도 ${selectAreaItem.value.minutes}분)`
  formData.value.bornAreaId = `${selectAreaItem.value.id}`
  isPopAreaBox.value = false
  formData.value.addressTxt = `${selectAreaItem.value.korCountryName}, ${selectAreaItem.value.korCityName} (${selectAreaItem.value.longitudeType} ${selectAreaItem.value.degrees}도 ${selectAreaItem.value.minutes}분)`
}

const isPopMansePrint = ref(false)
const closePopMansePrint = () => {
  isPopMansePrint.value = false
}
const dialogPrint = ref(null)
function printManse() {
  let cnt = 0
  cnt = Object.keys(rsDataAll.value)?.length
  if (cnt > 0) {
    // 출력물 세션 저장
    sessionStorage.setItem('printData', JSON.stringify(rsDataAll.value))
    sessionStorage.setItem('printformData', JSON.stringify(formData.value))
    sessionStorage.setItem('sortMode', sortMode.value)

    isPopMansePrint.value = true
  } else {
    showDialogAlert('사주조회 후 이용하여 주시기 바랍니다.')
  }
}

const selectedMemoListItem = ref({})
const selectedMemoListItemVal = ref(null)
const memoModeType = ref(null)

//메모 열기
const openMemoRegFirst = () => {
  if (userInfo === null || typeof userInfo?.name === 'undefined') {
    showDialogAlert('로그인 후 이용 가능 합니다.')
    return
  }
  const item = {}
  item.guestUserName = formData.value.userName
  item.birthTime = `${formData.value.hour}:${formData.value.min}`
  item.gender = useFun.getGenderTxt3(formData.value.gender)
  item.solar = transdata.value.solar
  item.lunar = transdata.value.lunar

  item.age = transdata.value.age
  item.ageKor = transdata.value.kor_age
  item.firstReg = true
  item.guestUserMemoContent = memoContent.value
  item.guestUserId = id
  if (pgmode.value === 'rs' && memoCount.value > 0) {
    selectedMemoListItem.value = item
    openMemoList()
  } else if (pgmode.value === 'rs' && memoCount.value === 0) {
    selectedMemoListItem.value = item
    selectedMemoListItemVal.value = item
    memoModeType.value = 'reg'
    openMemoReg()
  } else {
    selectedMemoListItem.value = item
    selectedMemoListItemVal.value = item
    memoModeType.value = 'reg_first'
    openMemoReg()
  }
}

//메모저장
const saveMemoFirst = (mContent) => {
  memoContent.value = mContent
  memoCount.value = 1
}

import { getMemoList } from '@/api/UserGroup'

const memoData = ref([])
const memoListData = ref({})
const listGroupMemoRefresh = async (memoTxt = '') => {
  try {
    const params = {}
    if (memoTxt !== '') params.content = memoTxt
    params.guestUserId = selectedMemoListItem.value.guestUserId
    params.sort = 'createdAt.desc'
    const data = await getMemoList(params) // 그룹리스트 조회
    memoData.value = data.content

    // listData 초기화
    Object.keys(memoListData.value).forEach((key) => {
      delete memoListData.value[key]
    })

    // 데이터 그룹화
    memoData.value.forEach((data) => {
      const createdAt = new Date(data.createdAt)
      const monthYear = `${createdAt.getFullYear()}-${`0${createdAt.getMonth() + 1}`.slice(-2)}`

      if (!memoListData.value[monthYear]) {
        memoListData.value[monthYear] = []
      }

      memoListData.value[monthYear].push(data)
    })
    memoCount.value = memoData.value.length
  } catch (error) {
    console.error('Failed to load users:', error)
  }
}

function openMemoListReg(selectedItem) {
  selectedMemoListItem.value = selectedItem
  selectedMemoListItemVal.value = null
  selectedMemoListItem.value.content = ''
  memoModeType.value = 'list'
  openMemoReg()
}

function openMemoListView(selectedMemo, selectedItem) {
  selectedMemoListItemVal.value = selectedMemo
  selectedMemoListItem.value = selectedItem
  memoModeType.value = 'view'
  openMemoReg()
}

function updateMemoEdit() {
  memoModeType.value = 'edit'
}

const handleReportClick = async (typ) => {
  let cnt = 0
  cnt = Object.keys(rsDataAll.value)?.length
  if (cnt > 0 && typ === 'reset') {
    /*
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('새로운 사주로 입력 하시겠습니까? 입력한 내용이 초기화 됩니다.')) {
      return
    }
    */
    await showDialogConfirm(
      '새로운 사주로 입력 하시겠습니까?',
      '입력한 내용이 초기화 됩니다.',
      '확인',
      handleReportClick2(typ),
    )
    return
  } else {
    handleReportClick2(typ)
  }
}

const handleReportClick2 = (typ) => {
  if (
    rsData01.value === false &&
    (areaTxt.value === null || areaTxt.value === '') &&
    formData.value.gender === null &&
    formData.value.year === '' &&
    formData.value.month === '' &&
    formData.value.hour === '' &&
    formData.value.day === '' &&
    formData.value.min === ''
  ) {
    showDialogAlert('초기화 상태입니다. ')
    return
  }
  if (typ === 'reset') {
    rsData01.value = false
    rsDataNor.value = false
    areaTxt.value = null
    formData.value = {
      year: '',
      month: '',
      hour: '',
      day: '',
      min: '',
      gender: null,
      userName: '',
      isLunar: false,
      isLeapYear: null,
      bornAreaId: null,
      birth_time: null,
      option1: true,
      option2: true,
      option3: false,
      option4: true,
      addressTxt: null,
      lang: 'KOR',
    }
    //popAreaCity.value = null
    // 출력물 비우기
    rsDataAll.value = {}
  }
  if (typ === 'rs01') {
    rsData01.value = true
    rsDataNor.value = false
  }
  if (typ === 'nor') {
    rsDataVip.value = false
    rsDataNor.value = true
  }
  if (typ === 'vip') {
    rsDataNor.value = false
    rsDataVip.value = true
  }
}
const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref(null)

const dialogConfrim = ref(null)

//FIX 알림창
const showDialogAlert = (title, text = '') => {
  dialogTitle.value = title
  if (text) dialogText.value = text
  dialogAlert.value.openAlertDialog()
}
const showDialogConfirm = (title, title2 = '', text = '', nextUrl = null) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  if (text) dialogText.value = text
  if (nextUrl) dialogNextUrl.value = nextUrl
  dialogConfrim.value.openConfirmDialog()
}

const checkLength = (fieldId, value) => {
  if (fieldId === 'monthField') {
    if (value.length === 4) {
      const monthField = document.getElementById(fieldId)
      monthField.focus()
    }
  } else {
    if (value.length === 2) {
      const monthField = document.getElementById(fieldId)
      monthField.focus()
    }
  }
}

const isPopNameBox = ref(false)

const openUserName = () => {
  isPopNameBox.value = true
}

const onChangeName = async (name) => {
  const result = await getDeatil(id.value)
  result.guestUserName = name
  const upResult = await updateGroup(result)
  isPopNameBox.value = false
  inputdata.value.name = name
}

const checkOption4Checkbox = () => {
  if (!formData.value.option4 && areaTxt.value !== null) {
    showDialogAlert('지역을 적용하신 경우, 한국시만 적용됩니다.')
    formData.value.option4 = true
  }
}
</script>

<template>
  <div class="content-main bg-body-gray" :class="[colorMode, langMode]">
    <section class="inner-base manse-hd-wrap">
      <div class="banner-box mb10" :class="{ rspage: pgmode === 'rs' }">
        <img src="/src/assets/images_manse/banner02.png" alt="" />
      </div>
      <div class="spacer" />
      <section class="inner-base-3" :class="{ rspage: pgmode === 'rs' }">
        <section class="manse-search-box mb10" :class="{ rspageForm: pgmode === 'rs' }">
          <div class="tit-exp">*생년월일시와 옵션을 활용해서 사주팔자를 확인해보세요.</div>
          <VForm ref="contentForm">
            <div class="search-form-box">
              <div class="search-box w-short">
                <div class="item-box">
                  <div class="txt wd100">
                    <div class="txt-gender-box">
                      <input
                        v-model="formData.gender"
                        id="rd-genter-male"
                        name="gender"
                        type="radio"
                        class="rd-gender male"
                        value="1"
                      /><label class="" for="rd-genter-male">남자</label>
                      <input
                        v-model="formData.gender"
                        id="rd-genter-female"
                        name="gender"
                        type="radio"
                        class="rd-gender female"
                        value="0"
                      /><label class="line" for="rd-genter-female">여자</label>
                    </div>
                    <div class="txt-gender-box">
                      <input
                        id="date1"
                        type="radio"
                        name="isLunar"
                        class="rd-orange"
                        value="false"
                        v-model="formData.isLunar"
                        @click="handleLeapLunarChange"
                      /><label for="date1">양력</label>
                      <input
                        id="date2"
                        type="radio"
                        name="isLunar"
                        class="rd-orange"
                        value="true"
                        v-model="formData.isLunar"
                        @click="handleLeapLunarChange"
                      /><label class="line" for="date2">음력</label>
                      <input
                        id="date3"
                        type="checkbox"
                        name="isLeapYear"
                        class="rd-orange"
                        v-model="formData.isLeapYear"
                        @change="handleLeapYearChange"
                      /><label class="line" for="date3">윤달</label>
                    </div>
                    <span class="btn-right">
                      <button class="btn-bg-point" type="button" @click="isPopGanjiBox = true">
                        사주간지 입력
                      </button>
                    </span>
                  </div>
                </div>
                <div class="item-box">
                  <div class="txt-year-box">
                    <span class="input-relative">
                      <VTextField
                        v-model="formData.year"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="4"
                        @keydown.enter="focusNext('monthField')"
                        @input="checkLength('monthField', formData.year)"
                      >
                        <template #append-inner>
                          <span class="txt-r">년</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="monthField"
                        v-model="formData.month"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        @keydown.enter="focusNext('dayField')"
                        @input="checkLength('dayField', formData.month)"
                      >
                        <template #append-inner>
                          <span class="txt-r">월</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="dayField"
                        v-model="formData.day"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        @keydown.enter="focusNext('hourField')"
                        @input="checkLength('hourField', formData.day)"
                      >
                        <template #append-inner>
                          <span class="txt-r">일</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="hourField"
                        v-model="formData.hour"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        :disabled="isCheckboxChecked"
                        @keydown.enter="focusNext('minField')"
                        @input="checkLength('minField', formData.hour)"
                      >
                        <template #append-inner>
                          <span class="txt-r">시</span>
                        </template>
                      </VTextField>
                    </span>
                    <span class="input-relative">
                      <VTextField
                        id="minField"
                        v-model="formData.min"
                        class="h-small inputNext align-center"
                        variant="underlined"
                        maxlength="2"
                        :disabled="isCheckboxChecked"
                        @keydown.enter="searchEowayDo()"
                      >
                        <template #append-inner>
                          <span class="txt-r">분</span>
                        </template>
                      </VTextField>
                    </span>
                    <span>
                      <input
                        id="birth_time"
                        v-model="formData.birth_time"
                        type="checkbox"
                        class="checkbox"
                        @change="handleBirthTime"
                      /><label class="label font" for="birth_time">시간 모름</label>
                    </span>
                  </div>
                </div>
                <div class="item-box">
                  <div class="txt-text-box">
                    <VTextField
                      v-model="formData.userName"
                      class="h-small inputNext"
                      placeholder="이름 또는 닉네임 (선택사항)"
                      variant="underlined"
                      clearable
                      maxlength="20"
                    />
                    <!-- <div
                      class="input w100 mr10"
                      @click="handleFocusArea"
                    >
                      {{ areaTxt ? areaTxt : '출생 지역 검색 (해외 출생지 포함)' }}
                    </div> -->
                    <VTextField
                      ref="areaField"
                      v-model="areaTxt"
                      class="h-small inputNext"
                      placeholder="출생 지역 검색 (해외 출생지 포함)"
                      variant="underlined"
                      readonly
                      clearable
                      @click="handleFocusArea"
                    />
                  </div>
                </div>
              </div>
              <div v-if="isOptionInput" class="search-box right">
                <div class="btn-mark-box">
                  <button class="btn-mark" type="button" @click="isOptionInput = false">
                    <span>닫기</span> <i class="i" />
                  </button>
                </div>
                <div class="item-box">
                  <div class="tit">
                    한국시 적용 <i class="btn-exp">설명</i>
                    <!-- @click="setKorTime"-->
                  </div>
                  <div class="txt">
                    <input
                      id="kor_time"
                      ref="korTimeCheckbox"
                      type="checkbox"
                      class="checkbox"
                      v-model="formData.option4"
                      @change="checkOption4Checkbox"
                    /><label class="label" for="kor_time">한국시 적용</label>
                    <div class="tit ml-60" style="width: 175px">
                      야자시/조자시 <i class="btn-exp">설명</i>
                    </div>
                    <div class="txt">
                      <input
                        id="chk0101"
                        type="checkbox"
                        class="checkbox"
                        v-model="formData.option1"
                      />
                      <label for="chk0101" class="label">체크</label>
                    </div>
                  </div>
                </div>
                <div class="item-box">
                  <div class="tit">절기 선택<i class="btn-exp">설명</i></div>
                  <div class="txt">
                    <input
                      id="ra0101"
                      type="radio"
                      class="radio"
                      name="ra01"
                      :value="true"
                      v-model="formData.option2"
                    /><label for="ra0101" class="label font">동지</label>
                    <input
                      id="ra0102"
                      type="radio"
                      class="radio"
                      name="ra01"
                      :value="false"
                      v-model="formData.option2"
                    /><label for="ra0102" class="label font">입춘</label>
                    <input
                      id="ra0103"
                      type="radio"
                      class="radio"
                      name="ra01"
                      value="none"
                      v-model="formData.option2"
                    /><label for="ra0103" class="label font">모름</label>
                  </div>
                </div>
                <div class="item-box">
                  <div class="tit" style="display: none">현재 옵션저장</div>
                  <div class="txt" style="display: none">
                    <input id="opt_save" type="checkbox" class="checkbox" /><label
                      class="label line"
                      for="opt_save"
                    />
                    <span class="line-txt pointer" @click="isPopCurrentOptionBox = true"
                      >옵션보기</span
                    >
                  </div>
                </div>
                <div class="btn-box ml-auto">
                  <button class="sBtn nor" type="button" @click="handleReportClick('reset')">
                    <i class="icon-restart">아이콘</i>초기화
                  </button>
                  <button class="sBtn" type="button" @click="searchEowayDo()">
                    <i class="icon-search" />조회하기
                  </button>
                </div>
              </div>
              <div v-if="!isOptionInput" class="search-box right option">
                <div class="btn-mark-box">
                  <button class="btn-mark" type="button" @click="isOptionInput = true">
                    <span>옵션</span> <i class="i" />
                  </button>
                </div>
                <ul class="opt-list">
                  <li>
                    • 사주구성의 다양한 조건을 직접 설정하실 수 있도록 <br />야자시/조자시, 한국시,
                    지역시, 동지, 입춘 옵션을 제공합니다.
                  </li>
                  <li>• 최근 조회한 옵션은 자동저장 됩니다. (회원서비스)</li>
                  <!-- <li>
                    • 낭만만세력은 사주구성의 다양한 조건을 직접 설정하실 수 있도록 편의기능을 제공합니다.
                  </li>
                  <li>
                    • 선택 옵션 <span class="icon-arrow02" /> 야자시/조자시, 한국시, 지역시, 동지, 입춘
                  </li>
                  <li>
                    • 옵션의 뜻과 의미는 해당 단어를 클릭하여 확인 가능합니다.
                  </li>
                  <li>
                    • 최근 조회한 옵션으로 자동 저장됩니다.
                    <span class="line-btm">회원 전용 서비스</span>
                  </li> -->
                </ul>
                <div class="btn-box ml-auto">
                  <button class="sBtn nor" type="button" @click="handleReportClick('reset')">
                    <i class="icon-restart">아이콘</i>초기화
                  </button>
                  <button class="sBtn" type="button" @click="searchEowayDo()">
                    <i class="icon-search" />조회하기
                  </button>
                </div>
              </div>
            </div>
          </VForm>
          <!-- // end : search-form-box // -->
        </section>
        <!-- // end : manse-search-box // -->
      </section>
      <ComLatest
        ref="refComLatest"
        :pr-pgmode="pgmode"
        :pr-data="rsDataAll"
        @printclick="printManse()"
      />
    </section>
    <section>
      <div v-if="isLoading" class="loading-full-wrap">
        <div class="inner-box">
          <div ref="loadingContainer" class="loading-container">
            <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
          </div>
        </div>
      </div>
      <transition name="fade" appear>
        <div v-if="rsData01" :class="{ rspageSaju: pgmode === 'rs' }">
          <section class="inner-base result-10-box">
            <section ref="targetDiv" class="result-01-box mb20">
              <div class="people-box">
                <div class="mem-info">
                  <div class="profile-box">
                    <div class="profile-img">
                      {{ birthData.sky.day.value }}
                    </div>
                    <!-- <span class="icon-search-s-red btn-set">검색</span> -->
                  </div>
                  <div class="name-box">
                    <div v-if="inputdata.name" class="name">
                      <!--name-short--->
                      {{ inputdata.name }} ({{ useFun.getGenderTxt(inputdata.gender) }})
                    </div>
                    <div v-else class="name">- ({{ useFun.getGenderTxt(inputdata.gender) }})</div>
                    <div class="age">
                      <!-- {{ inputdata.name }} -->
                      {{ transdata?.kor_age }}세 (만{{ transdata?.man_age }}세)
                      <!-- {{ calculateAge() }}세 -->
                    </div>
                    <div class="btn-mod-box">
                      <button v-if="pgmode === 'rs'" class="btn-modify" @click="openUserName()">
                        수정
                      </button>
                    </div>
                  </div>
                </div>
                <div class="birth-box">
                  <div class="mr25">
                    <span class="tits orange">양력</span>
                    <span class="txts"
                      >{{ useFun.formatCustomDate(transdata?.solar) }} ({{ transdata.week }})
                      {{
                        five.getBirthTimeTxt(
                          five.getTimeTxt2(inputdata.hour) + ':' + five.getTimeTxt2(inputdata.min),
                        )
                      }}
                    </span>
                  </div>
                  <div class="">
                    <span class="tits blue">음력</span>
                    <span class="txts">{{ useFun.formatCustomDate(transdata?.lunar) }}</span>
                  </div>
                  <div class="w-wide">
                    <span class="tits">옵션</span>
                    <span class="txts">
                      <span v-if="transdata?.bornArea?.county">
                        {{ transdata?.bornArea.county }}, {{ transdata?.bornArea.city }},
                      </span>
                      {{ useFun.formatOption4Txt(inputdata.koreantime) }}
                      {{ useFun.formatOption1Txt(inputdata.earlytime) }}
                      {{ useFun.formatOption2Txt(inputdata.dongji) }}
                    </span>
                  </div>
                </div>
                <div ref="hiddenBtn" class="btn-box btn-rd trans-btn-box">
                  <v-row v-model="btnLang01" class="btn-tooltip">
                    <button
                      v-if="langMode === 'CHNKOR'"
                      class="btn-rd btn-rd-38 chnkor"
                      @click="searchEowayTrans('CHN')"
                    >
                      한자/한글
                    </button>
                    <!-- @click="searchEowayTrans('CHNKOR')" -->
                  </v-row>
                  <v-row v-model="btnLang02" class="btn-tooltip">
                    <button
                      v-if="langMode === 'CHN'"
                      class="btn-rd btn-rd-38 chn"
                      @click="searchEowayTrans('KOR')"
                    >
                      한자
                    </button>
                  </v-row>
                  <v-row v-model="btnLang03" class="btn-tooltip">
                    <button
                      v-if="langMode === 'KOR'"
                      class="btn-rd btn-rd-38 kor"
                      @click="searchEowayTrans('CHNKOR')"
                    >
                      한글
                    </button>
                  </v-row>
                  <v-row v-model="btnfntcolor01" class="btn-tooltip">
                    <button
                      v-if="colorMode === 'fntcolor01'"
                      class="btn-rd btn-rd-38 fntcolor01"
                      @click="searchEowayTransColor('fntcolor02')"
                    >
                      색상변환01
                    </button>
                    <!-- @click="searchEowayTrans('CHNKOR')" -->
                  </v-row>
                  <v-row v-model="btnfntcolor02" class="btn-tooltip">
                    <button
                      v-if="colorMode === 'fntcolor02'"
                      class="btn-rd btn-rd-38 fntcolor02"
                      @click="searchEowayTransColor('fntcolor03')"
                    >
                      색상변환02
                    </button>
                  </v-row>
                  <v-row v-model="btnfntcolor03" class="btn-tooltip">
                    <button
                      v-if="colorMode === 'fntcolor03'"
                      class="btn-rd btn-rd-38 fntcolor03"
                      @click="searchEowayTransColor('fntcolor01')"
                    >
                      색상변환03
                    </button>
                  </v-row>
                  <button
                    class="btn-rd btn-rd-38 sorting"
                    :class="{ on: sortMode === 'asc' }"
                    @click="sortChange()"
                  >
                    순서변경
                  </button>
                  <button
                    class="btn-rd btn-rd-38 grally"
                    :class="{ on: rsDataNor === true }"
                    @click="handleDefaultReport()"
                  >
                    갤러리형
                  </button>
                  <!-- <v-btn
                    class="btn-nor"
                    @click="downloadAsImage"
                  >
                    다운로드 (test)
                  </v-btn> -->
                  <span class="line" />
                  <button class="btn-line txt-ellipsis" @click="groupListOpen">
                    <span>{{ useFun.truncateString(userGroupName, 5) }}</span>
                  </button>
                  <button class="btn-line" @click="openMemoRegFirst()">
                    <span class="name">메모</span> <span class="count">{{ memoCount }}</span>
                  </button>
                  <span class="line" />
                  <button
                    v-if="pgmode !== 'rs'"
                    class="btn-save btn-sq-40 btn"
                    :class="{ active: isSaveActive }"
                    @click="regSave()"
                  >
                    저장하기
                  </button>
                  <button v-if="pgmode === 'rs'" class="btn-del-line" @click="delComLast()">
                    삭제
                  </button>
                  <button
                    v-if="pgmode === 'rs'"
                    class="btn-list list btn-sq-40 btn"
                    @click="goList()"
                  >
                    목록으로
                  </button>
                </div>
              </div>
              <div class="data-sec-01-box">
                <div class="data-0101-root-box">
                  <ManseBirthMain
                    ref="ManseBirthMainArea"
                    :pr-birth-data="birthData"
                    :pr-birth-jijanggan="birthJijanggan"
                    :pr-lang-mode="langMode"
                    :pr-birth-title="birthTitle"
                    :pr-transdata="transdata"
                    :pr-type="'birth'"
                    :pr-cur-daeun="curDaeun"
                  />
                  <div class="data-0101-state" :class="{ active: isDaeunCheck }">
                    <!--'active': daeunCurNum !== transdata.curlucknum--->
                    <button class="btn-daeun" @click="isDaeunCheck = !isDaeunCheck">
                      대운 적용<i class="icon-check01" />
                    </button>
                    <div class="state-row sec-daeun-default">
                      <div class="name">사주 오행</div>
                      <div class="five-count-box data">
                        <span class="item-box"
                          ><em class="txt five03 ganji-txt">木</em
                          ><em class="cnt">{{ birthData?.five?.five1?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five01 ganji-txt">火</em
                          ><em class="cnt">{{ birthData?.five?.five2?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five05 ganji-txt">土</em
                          ><em class="cnt">{{ birthData?.five?.five3?.num }}</em></span
                        >

                        <span class="item-box"
                          ><em class="txt five04 ganji-txt">金</em
                          ><em class="cnt">{{ birthData?.five?.five4?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five02 ganji-txt">水</em
                          ><em class="cnt">{{ birthData?.five?.five5?.num }}</em></span
                        >
                      </div>
                    </div>
                    <div class="state-row sec-daeun-selected">
                      <div class="name"><i class="icon-frame" /><span>대운 적용</span></div>
                      <div class="five-count-box data">
                        <span class="item-box"
                          ><em class="txt five03 ganji-txt">木</em
                          ><em class="cnt">{{ birthFiveCnt?.five1?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five01 ganji-txt">火</em
                          ><em class="cnt">{{ birthFiveCnt?.five2?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five05 ganji-txt">土</em
                          ><em class="cnt">{{ birthFiveCnt?.five3?.num }}</em></span
                        >

                        <span class="item-box"
                          ><em class="txt five04 ganji-txt">金</em
                          ><em class="cnt">{{ birthFiveCnt?.five4?.num }}</em></span
                        >
                        <span class="item-box"
                          ><em class="txt five02 ganji-txt">水</em
                          ><em class="cnt">{{ birthFiveCnt?.five5?.num }}</em></span
                        >
                      </div>
                    </div>
                    <div class="state-row">
                      <!--sec-daeun-default-->
                      <div class="name">천간 변화</div>
                      <div class="data" :class="{ 'none-data': hapDataVal === '' }">
                        {{ hapDataVal || '원국 천간변화가 없습니다.' }}
                      </div>
                    </div>
                    <!-- <div class="state-row sec-daeun-selected">
                      <div class="name">
                        <i class="icon-frame" /><span>대운 적용</span>
                      </div>
                      <div class="data">
                        {{ hapDataVal }}
                      </div>
                    </div> -->

                    <div class="state-row">
                      <!--sec-daeun-default-->
                      <div class="name">지지 변화</div>
                      <div class="data" :class="{ 'none-data': hapDataValEarth === '' }">
                        {{ hapDataValEarth || '원국 지지변화가 없습니다.' }}
                      </div>
                    </div>
                    <!-- <div class="state-row sec-daeun-selected">
                      <div class="name">
                        <i class="icon-frame" /><span>대운 적용</span>
                      </div>
                      <div class="data">
                        {{ hapDataValEarth }}
                      </div>
                    </div> -->
                    <div class="state-row">
                      <div class="name">공망 / 태월</div>
                      <div class="data">
                        {{ gongDataVal }}
                      </div>
                    </div>
                    <!-- <div class="state-row">
                      <div class="name">
                        태월
                      </div>
                      <div class="data">
                        {{ TaeDataVal }}
                      </div>
                    </div> -->
                    <!-- <div class="state-row">
                      <div class="name">
                        MBTI
                      </div>
                      <div class="data">
                        <span class="bg-point">로그인 후 사주로 보는 MBTI</span>
                      </div>
                    </div> -->
                  </div>
                  <div class="data-0101-banner">
                    <img src="/src/assets/images_manse/banner03.png" alt="" />
                  </div>
                </div>
                <div class="">
                  <div class="deaun-main-box">
                    <div class="tit-box">
                      <span>대운</span>
                    </div>
                    <ManseDaeunMain
                      :pr-birth-data="daeunsData"
                      :pr-lang-mode="langMode"
                      :pr-type="'daeun'"
                      :pr-cur-num="daeunCurNum"
                      @daeunclick="getDaeun"
                    />
                  </div>
                  <div v-if="isDaeunYear" class="deaun-main-box">
                    <div class="tit-box">
                      <span>세운</span>
                      <button
                        class="btn-fold"
                        @click=";(isDaeunYear = false), (isDaeunMonth = false)"
                      >
                        펼침/닫기
                      </button>
                    </div>
                    <ManseDaeunMain
                      :pr-birth-data="yearsData"
                      :pr-lang-mode="langMode"
                      :pr-type="'seun'"
                      :pr-cur-num="daeunYearCurNum"
                      @daeunclick="getDaeun"
                    />
                  </div>
                  <div v-if="isDaeunMonth" class="deaun-main-box">
                    <div class="tit-box">
                      <span>월운</span>
                      <button class="btn-fold" @click="isDaeunMonth = false">펼침/닫기</button>
                    </div>
                    <ManseDaeunMain
                      :pr-birth-data="monthsData"
                      :pr-lang-mode="langMode"
                      :pr-type="'month'"
                      :class="'month'"
                      :pr-cur-num="daeunMonthCurNum"
                      @daeunclick="getDaeun"
                    />
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </transition>
    </section>
    <transition name="fade" appear>
      <section v-if="rsDataNor" :class="{ rspageSaju: pgmode === 'rs' }">
        <section ref="rsGalleryBox" class="hd-inner-base bg-tab-cont manse-btm-ect-list">
          <button class="btn-close-xs" @click="handleDefaultReport()">닫기버튼</button>
          <div class="tab-content-container">
            <div class="tab-content-box">
              <ManseCard
                :pr-data-all="rsDataAll"
                :pr-sort-mode="sortMode"
                :pr-lang-mode="langMode"
              ></ManseCard>
            </div>
          </div>
        </section>
      </section>
    </transition>
  </div>
  <section v-if="pgmode === 'rs'" class="btn-com gap-xl ceneter">
    <button class="btn-line w-large bg-w" @click="goList()">목록으로</button>
  </section>
  <div class="gap-big"></div>
  <!--그룹선택 팝업-->
  <PopSajuGroupChange
    v-if="isPopSajuGroupChange"
    :isDialogVisibleChange="true"
    @click="closeGroupChange"
    @change="changeGroup"
    @openSetting="openGroupSetting"
  />
  <!--그룹관리 팝업-->
  <PopSajuGroupSetting
    v-if="isPopSajuGroupSetting"
    :isDialogVisibleSetting="true"
    @change="changeGroup"
    @openSetting="openGroupSetting"
    @close="closeGroupSetting"
  />

  <!--메모등록 팝업-->
  <PopMemoReg
    v-if="isPopMemoReg"
    ref="popMemoListRef"
    :pr-selected-memo="selectedMemoListItem"
    :pr-memo-mode-type="memoModeType"
    :pr-selected-memo-val="selectedMemoListItemVal"
    :pr-transdata="transdata"
    :pr-inputdata="inputdata"
    @saveMemoFirst="saveMemoFirst"
    @listGroupMemo="listGroupMemoRefresh"
    @updateMemoEdit="updateMemoEdit"
    @close="closeMemoReg"
  />

  <!--메모목록 팝업-->
  <PopMemoList
    v-if="isPopMemoList"
    :pr-selected-memo="selectedMemoListItem"
    :pr-selected-memo-val="selectedMemoListItem"
    :pr-memo-mode-type="memoModeType"
    :pr-memo-list-data="memoListData"
    @saveMemoFirst="saveMemoFirst"
    @close="closeMemoList"
    @openMemoView="openMemoListView"
    @open="openMemoListReg"
    @listMemo="listGroupMemoRefresh"
  />
  <!-- 사주간지입력 (팝업)-->
  <PopGanji v-if="isPopGanjiBox" @close="isPopGanjiBox = false" @searchinput="searchInput" />

  <!-- 주소검색 (팝업)-->
  <PopArea
    v-if="isPopAreaBox"
    @close="isPopAreaBox = false"
    @searchinput="searchInput"
    @selectAreaFinish="selectAreaFinish"
  />

  <!--출력화면-->
  <PopMansePrint
    ref="dialogPrint"
    v-if="isPopMansePrint"
    @close="closePopMansePrint()"
    @click:outside="closePopMansePrint()"
  />

  <!--Alert창-->
  <AlertComponent ref="dialogAlert" :title="dialogTitle" :text="dialogText" />

  <ConfirmComponent
    ref="dialogConfrim"
    :title="dialogTitle"
    :title2="dialogTitle2"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />

  <!--이름 수정-->
  <PopNameComponent
    v-if="isPopNameBox"
    :username="inputdata.name"
    @onChangeName="onChangeName"
    @close="isPopNameBox = false"
  />
</template>
<style lang="scss">
@import '@/assets/css/style.scss';
@import '@/assets/nangman_style/manse.scss';
@import '@/assets/nangman_style/manse_k.scss';
</style>

<style>
.rspage {
  height: 0;
  width: 0;
  visibility: hidden;
}
@media (min-width: 600px) {
  .v-dialog.v-dialog-sm .v-overlay__content,
  .v-dialog.v-dialog-lg .v-overlay__content,
  .v-dialog.v-dialog-xl .v-overlay__content {
    inline-size: auto !important;
  }
}
</style>
<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 0.3;
  position: absolute;
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
