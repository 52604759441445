<!-- src/pages/MyPage.vue -->
<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';

import userGroup from "@/pages/saju/components/userGroup.vue";
import sajuGrid from "@/pages/saju/components/sajuGrid.vue";
import sajuList from "@/pages/saju/components/sajuList.vue";
import IconDivider from '@/components/svg/IconDivider.vue';
import IconDownFill from '@/components/svg/IconDownFill.vue';

import sajuFilter from "@/pages/saju/components/sajuFilter.vue";
import sajuFilterNew from './components/sajuFilterNew.vue';
import { getGroupUserlist, getDeatil, updateGroup } from '@/api/UserGroup';
import { fastSearch } from '@/api/Eoway';
import {groupListOpen, isPopSajuGroupChange, closeGroupChange, openGroupSetting, isPopSajuGroupSetting, closeGroupSetting, openGroupChange, openMemoReg, closeMemoReg, isPopSajuGroupAdd, currentTab, closeGroupAdd } from '@/composables/useGroup';

//popup
import PopSajuGroupChange from '@/pages/saju/components/PopSajuGroupChange.vue';
import PopSajuGroupSetting from '@/pages/saju/components/PopSajuGroupSetting.vue';
import PopMemoReg from '@/pages/saju/components/PopMemoReg.vue';
import PopMemoList from '@/pages/saju/components/PopMemoList.vue';
import PopAddGroup from './components/PopAddGroup.vue';

import PopMansePrint from '@/pages/manse/components/PopMansePrint.vue';
import PopShareBox from '@/pages/manse/components/PopShareBox.vue';

//Alert
import AlertComponent from '@/pages/manse/components/Alert.vue';

//UserAuth 
import { getUserLogin } from "@/composables/useAuthStore";



const route = useRoute();
const router = useRouter(); 

definePage({ meta: { layout: "manse" } });

//목록타입
let isListType = ref('grid');

//리스트 정보 조회
let listData = ref([]);
let totalElements = ref(0);
let totalPages = ref(0);
const isLoading = ref(false);

//검색조건
let paramData = ref({
  birthStart: null,
  birthEnd: null,
  startDate: null,
  endDate: null,
  genderOption: 0,
  page: 1,
  size:15,
  searchKeyWord: '',
  sortOption: null,
  userGroupId: null,
  activeOpt1: false,
  activeOpt2: false,
  activeOpt3: false,
  activeOpt4: false,
  fiveEles: [],
  gender:null,
  filterFlag:false,
  isFavorite: null,
});

const date = '';

const resetAll = ()=>{
  paramData.value.birthStart = null
  paramData.value.birthEnd = null
  paramData.value.startDate = null
  // paramData.value.userGroupId = null
  paramData.value.searchKeyWord = ''
  paramData.value.activeOpt1 = false
  paramData.value.activeOpt2 = false
  paramData.value.activeOpt3 = false
  paramData.value.activeOpt4 = false
  paramData.value.fiveEles = []
  paramData.value.gender = []
  paramData.value.page = 1

  // selectedSortOption.value = {
  //   state: '등록순',
  //   abbr: 'createdAt.desc',
  // };
  // paramData.value.sort = 'createdAt.desc';
  fetchListOne(isListType.value);
  // refUserGroup.value.fetchGroupReset();
  if (refUserFilter.value) {
    refUserFilter.value.fetchFilterReset();
  } else {
    console.error('refUserFilter가 정의되지 않았습니다.');
  }
}

const requestFetchList = () => {
  paramData.value.searchKeyWord = ''
  fetchListOne(isListType.value);
}

const setParamData = (noOrNewParamData) => {
  if (typeof noOrNewParamData === 'object') {
    paramData.value = { ...paramData.value, ...noOrNewParamData };
  } else {
    paramData.value[`activeOpt${noOrNewParamData}`] = !paramData.value[`activeOpt${noOrNewParamData}`];
  }
};
const setFilterData = (options) =>{
  paramData.value = { ...paramData.value, ...options.value };
  //alert("List :: "+paramData.value.fiveEles)
  if(paramData.value.gender === null || paramData.value.gender === ''){
    delete paramData.value.gender;
  }
  if(paramData.value.fiveEles === null || paramData.value.fiveEles === ''){
    delete paramData.value.fiveEles;
  }

  // if(paramData.value.filterFlag){
  //   paramData.value.page = 1;
  // }
  paramData.value.page = 1;
  fetchListOne(isListType.value);
}

const tabSearch = (userGroupId) =>{
  if(userGroupId > 0){
    paramData.value.userGroupId = userGroupId;
  }else{
    delete paramData.value.userGroupId
  }
  fetchListOne(isListType.value);
}

const showFavorite = () =>{
  paramData.value.isFavorite = true;
  fetchListOne(isListType.value);
}

const fetchList = async (typ, option='') => {
  try {
    isLoading.value = true;
    isListType.value = typ;
    const params = reactive({ ...paramData.value });
    const data = await getGroupUserlist(params); // 저장목록 조회
    totalElements.value = data.totalElements;
    totalPages.value = data.totalPages;
    listData.value = [...listData.value, ...data.content];
    //listData.value = [listData.value, data.content];

  } catch (error) {
    console.error('Failed to load users:', error);
  } finally {
    isLoading.value = false;
  }
};

const fetchListOne = async (typ) => {
  try { 
    isLoading.value = true;
    isListType.value = typ;
    const params = reactive({ ...paramData.value });
    const data = await getGroupUserlist(params); // 저장목록 조회
    totalElements.value = data.totalElements;
    totalPages.value = data.totalPages;
    listData.value =data.content;
  } catch (error) {
    console.error('Failed to load users:', error);
  } finally {
    isLoading.value = false;
  }
};


//더보기
async function moreList() {
  paramData.value.page += 1;
  await fetchList(isListType.value, 'more');
}

onMounted(async () => {
  const userId = await getUserLogin(); 
  if (!userId) {
    const nextUrl = '/auth/login';
    showDialogAlert('로그인 후 이용 가능 합니다.', '', nextUrl)
    return;
  }else{
    paramData.value.userId = userId
  } 
  await fetchList(isListType.value);
});

const selectedGroup = ref({});
const selectedListItem = ref({});
const userGroupId = ref(null);
const userGroupName = ref('미지정');
const refUserGroup = ref(null);
const refUserFilter = ref(null);


function handleGroupChange(selectedGroupItem) {
  openGroupChange();
  selectedGroup.value = selectedGroupItem;
  selectedListItem.value = selectedGroupItem;
}

//그룹선택저장
async function changeGroup(selectedChangeGroup){
  userGroupId.value = selectedChangeGroup[0]?.userGroupId;
  userGroupName.value = selectedChangeGroup[0]?.name;
  try {
    const result = await getDeatil(selectedGroup.value.guestUserId);
    let selectUserGroup = result;
    if (!selectUserGroup.userGroup) {
      selectUserGroup.userGroup = {}; // 빈 객체로 초기화
    }
    selectUserGroup.userGroup.userGroupId = userGroupId.value;
    const upResult = await updateGroup(selectUserGroup);
    const user = listData.value.find(item => item.guestUserId === selectedGroup.value.guestUserId);
    if (user) {
      user.userGroup.name = userGroupName.value;
      user.userGroup.userGroupId = userGroupId.value;
    }

  } catch (error) {
    showDialogAlert(error.message || 'Error occurred while fetching details.'); // 에러 메시지 표시
  }
  isPopSajuGroupChange.value = false;
}

//정렬변경
const onSortOptionChange = (value) => {
  paramData.value.sort = value.abbr;
  fetchListOne(isListType.value);
}
const selectedSortOption = ref({
  state: '등록순',
  abbr: 'createdAt.desc',
})

const sortOptionItems = [
  {
    state: '등록순',
    abbr: 'createdAt.desc',
  },
  {
    state: '이름순',
    abbr: 'guestUserName.asc',
  },
  {
    state: '나이순',
    abbr: 'age.desc',
  },
  {
    state: '목화토금수 순',
    abbr: 'fiveEle.asc',
  },
]


const isDialogVisible2 = ref(false)


function groupListDataRefresh() {
  if (refUserGroup.value) {
    refUserGroup.value.fetchGroup();
  }
  //본문 목록 재호출
  fetchListOne(isListType.value);
}


import { getMemoList } from '@/api/UserGroup';

const memoData = ref([]);
const memoListData = ref({});
const listGroupMemoRefresh = async (memoTxt='') => {
  try {
    const params = {}
    if(memoTxt !== '') params.content = memoTxt
    params.guestUserId = selectedMemoListItem.value.guestUserId;
    params.sort ='createdAt.desc';
    const data = await getMemoList(params); // 그룹리스트 조회
    memoData.value = data.content;

    // listData 초기화
    Object.keys(memoListData.value).forEach((key) => {
      delete memoListData.value[key];
    });

    // 데이터 그룹화
    memoData.value.forEach((data) => {
      const createdAt = new Date(data.createdAt);
      const monthYear = `${createdAt.getFullYear()}-${(`0${createdAt.getMonth() + 1}`).slice(-2)}`;

      if (!memoListData.value[monthYear]) {
        memoListData.value[monthYear] = [];
      }

      memoListData.value[monthYear].push(data);
    });
    //본문 목록 재호출
    fetchListOne(isListType.value);
  } catch (error) {
    console.error('Failed to load users:', error);
  }
}

const selectedMemoListItem = ref({});
const selectedMemoListItemVal = ref(null);
const memoModeType = ref(null);

//메모 열기
function handleOpenMemoReg(selectedItem, typ) {
  selectedMemoListItem.value = selectedItem;
  memoModeType.value = typ;
  openMemoReg();
  //openGroupChange();
  //selectedGroup.value = selectedGroupItem;
  //selectedListItem.value = selectedGroupItem;
}

function handleOpenMemoList(selectedItem, typ) {
  selectedMemoListItem.value = selectedItem;
  memoModeType.value = typ;
  openMemoList();
}

function openMemoListReg(selectedItem){
  selectedMemoListItem.value = selectedItem;
  selectedMemoListItemVal.value = null;
  memoModeType.value = 'list';
  openMemoReg();
}


function openMemoListView(selectedMemo, selectedItem) {
  selectedMemoListItemVal.value = selectedMemo;
  selectedMemoListItem.value = selectedItem;
  memoModeType.value = 'view';
  openMemoReg();
  //openGroupChange();
  //selectedGroup.value = selectedGroupItem;
  //selectedListItem.value = selectedGroupItem;
}

function updateMemoEdit(){
  memoModeType.value = 'edit';
}

const formData = ref({});
const isPopShareBox = ref(false);
const isPopMansePrint = ref(false);
const rsData = ref({});

const getEowaySearch = async (id) => {
  let rsFlag = false;
  const result = await getDeatil(id);

  formData.value.userName = result.guestUserName;
  formData.value.gender = result.gender === 'MEN' ? 1 : 0;
  formData.value.isLunar = result.isLuna;
  formData.value.isLeapYear = result.isLeapYear;
  formData.value.addressTxt = result.bornArea;
  formData.value.bornAreaId = result.bornAreaId;
  formData.value.option1 = result.option1;
  formData.value.option2 = result.option2;
  formData.value.option3 = result.option3;
  formData.value.option4 = result.option4;
  formData.value.option5 = result.option5;
  formData.value.birth = result.birth;
  formData.value.birthTime = result.birthTime;
  const rsDataAll = await fastSearch(formData.value);
  if (Object.keys(rsDataAll)?.length > 0) {
    rsData.value = rsDataAll;
    sessionStorage.setItem('printData', JSON.stringify(rsDataAll));
    sessionStorage.setItem('printformData', JSON.stringify(formData.value));
    rsFlag = true;
  }
  return rsFlag;
}
const shareData = ref(null);
const  shareOpen = async (id) => {
  const rsFalg = await getEowaySearch(id);
  if (rsFalg) {
    isPopShareBox.value = true;
  }
}
const share = () => {
  shareData.value = useFun.convertShareTxt(rsData.value);
}
const printManse = async(id) => {
  const rsFalg = await getEowaySearch(id);
  if (rsFalg) {
    isPopMansePrint.value = true;
  }
}
const closePopShareBox = () => {
  isPopShareBox.value = false;
}
const closePopMansePrint = () => {
  isPopMansePrint.value = false;
}


const dialogAlert = ref(null);
const dialogTitle = ref('');
const dialogText = ref('');
const dialogNextUrl = ref('');

const showDialogAlert = (title, text='', nextUrl = '') => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogNextUrl.value = nextUrl;
  dialogAlert.value.openAlertDialog();
};

/** 상세분석 클릭시 준비중 alert */
const showAnalysisAlert = () =>{
  showDialogAlert('준비중입니다');
}

// 그룹 팝업 탭 관리
// const currentTab = ref(0);

// 검색어 Chip (그냥 paramData로 보내면 실시간 연동됨)
const searchFilterKeyWord = ref('');
const handleSearch = () => {
  fetchListOne(isListType.value);
  if (paramData.value.searchKeyWord) {
    searchFilterKeyWord.value = paramData.value.searchKeyWord; 
    console.log('searchFilterKeyWord:', searchFilterKeyWord.value);
  }
};

/** 즐겨찾기 토글 버튼 */
const toggleFavoriteHandler = async(guestUserId, isFavorite) => {
  try{
    const user = listData.value.find(item => item.guestUserId === guestUserId);
    if(user) {
      user.isFavorite = isFavorite;
      const updatedUserData = {
        ...user,
        isFavorite: isFavorite
      };
      await updateGroup(updatedUserData);
    }
  }
  catch (error) {
    console.error('즐겨찾기 상태 업데이트 중 오류:', error);
  }
}



</script>
<template>
  <div class="content-main mb-large">
    <section class="inner-base">
      <div class="list-hd-box">
        <div class="tit-box">
          <p class="tit">
            저장목록
          </p>

        </div>
        <div class="row-box">
          <div class="exp">
            저장된 사주 정보의 그룹을 설정하여 편리하게 관리하실 수 있습니다.
          </div>
          <div
            class="search-box"
          >
            <VTextField
              type="text"
              class="input"
              variant="underlined"
              density="compact"
              clearable
              v-model="paramData.searchKeyWord"
              placeholder="이름, 생년월일(920101), 한글사주로 검색 가능합니다."
              @keyup.enter="handleSearch"
              @focus="wordFocus=true"
              @blur="wordFocus=false"
            >
              <template #append-inner>
                <VBtn variant="text" class="btn" @click="handleSearch">
                  <i class="icon-search" />
                </VBtn>
              </template>
            </VTextField>


          </div>
        </div>
      </div>

      <userGroup
        ref="refUserGroup"
        :pr-group-list-data="groupListData"
        @popGroupSetting="popGroupSetting()"
        @tabSearch="tabSearch"fetchGroup
        @showFavorite="showFavorite"
      />

      <div class="result-box">
        <!-- <div class="filter">
          <sajuFilter
          ref="refUserFilter"
          :paramData = "paramData"
          @update:paramData="setParamData"
          @change:filterData="setFilterData"
          />
        </div> -->
        <div class="data-box">
          <div class="hd-box">
            <div class="count">
              <div class="result-num">
                <i class="icon-filter">아이콘</i><span class="no">{{ totalElements }}건</span>
              </div>
          <div class="filter-box">
            <div class="new-filter">
              <sajuFilterNew
              ref="refUserFilter"
              :paramData = "paramData"
              :searchFilterKeyWord="searchFilterKeyWord"
              @update:paramData="setParamData"
              @update:searchFilterKeyWord="searchFilterKeyWord = $event"
              @requestFetchList="requestFetchList"
              @change:filterData="setFilterData"
              @resetAll="resetAll" />
            </div>
          </div>
              <div
                v-if="isSearchWord"
                class="search-list"
              >
                {{ paramData.searchKeyWord }}
                <button
                  class="close-button sm"
                  @click="delSearchWord() "
                >
                  삭제
                </button>
              </div>
            </div>
            <div class="btn-r">
              <!-- <button
                class="btn-line-2 mr8"
                @click="resetAll()"
              >
                <i class="icon-reset" /><span>초기화</span>
              </button> -->
              <VSelect
                v-model="selectedSortOption"
                :items="sortOptionItems"
                item-title="state"
                item-value="abbr"
                class="h-middle"
                label="정렬"
                persistent-hint
                return-object
                single-line
                placeholder="정렬"
                @update:modelValue="onSortOptionChange"
              >
              <template #appendInner>
                <IconDownFill />
              </template>
            </VSelect>
              <div class="view-style-btn">
                <button
                  class="btn-grid"
                  :class="{ 'active': isListType === 'grid' }"
                  @click="isListType = 'grid'"
                >
                  갤러리
                </button>
                <IconDivider class="divider"/>
                <button
                  class="btn-list"
                  :class="{ 'active': isListType === 'list' }"
                  @click="isListType = 'list'"
                >
                  리스트
                </button>
              </div>

            </div>
          </div>

          <div v-if="isListType === 'grid'">
            <sajuGrid :pr-list-data="listData"
              @printclick="printManse"
              @shareclick="shareOpen"
              @openChange = "handleGroupChange"
              @openMemoReg = "handleOpenMemoReg"
              @openMemoList = "handleOpenMemoList" 
              @showAnalysis="showAnalysisAlert"
              @toggleFavorite="toggleFavoriteHandler"
            />
          </div>
          <div v-if="isListType === 'list'">
            <sajuList :pr-list-data="listData"
              @openChange = "handleGroupChange"
              @openMemoReg = "handleOpenMemoReg"
              @openMemoList = "handleOpenMemoList"
              @printclick="printManse"
              @shareclick="shareOpen" 
              @showAnalysis="showAnalysisAlert"
              @toggleFavorite="toggleFavoriteHandler"
            />
          </div>

          <div
            v-if="paramData.page < totalPages"
            class="btn-com gap-xl ceneter"
          >
            <button
              class="btn-line w-large"
              @click="moreList"
            >
              더보기
              <!-- ({{ paramData.page }}/{{ totalPages }}) -->
            </button>
          </div>
        </div>

      </div>
    </section>
  </div>
  <!--그룹선택 팝업-->
  <PopSajuGroupChange v-if="isPopSajuGroupChange"
  :pr-selected-group="selectedGroup"
  @click="closeGroupChange"
  @change="changeGroup"
  @openSetting="openGroupSetting"
  />
  
  <!-- 그룹 추가 팝업-->
  <PopAddGroup v-if="isPopSajuGroupAdd"
  :currentTab="currentTab"
  @close="closeGroupAdd"
  @change="changeGroup"
  @refresh="groupListDataRefresh"
  @openSetting="openGroupSetting"
  />

  <!--그룹관리 팝업-->
  <PopSajuGroupSetting v-if="isPopSajuGroupSetting"
    @change="changeGroup"
    @openSetting="openGroupSetting"
    @close="closeGroupSetting"
    @refresh="groupListDataRefresh"
  />

  <!--메모등록 팝업-->
  <PopMemoReg v-if="isPopMemoReg"
    :pr-selected-memo="selectedMemoListItem"
    :pr-memo-mode-type="memoModeType"
    :pr-selected-memo-val = "selectedMemoListItemVal"
    @saveMemoFirst="saveMemoFirst"
    @listGroupMemo="listGroupMemoRefresh"
    @updateMemoEdit="updateMemoEdit"
    @close="closeMemoReg"
  />

  <!--메모목록 팝업-->
  <PopMemoList v-if="isPopMemoList"
    :pr-selected-memo="selectedMemoListItem"
    :pr-selected-memo-val = "selectedMemoListItem"
    :pr-memo-mode-type="memoModeType"
    :pr-memo-list-data="memoListData"
    @saveMemoFirst="saveMemoFirst"
    @close="closeMemoList"
    @openMemoView="openMemoListView"
    @open="openMemoListReg"
    @listMemo="listGroupMemoRefresh"
  />

  <!--출력화면-->
  <PopMansePrint
    v-if="isPopMansePrint"
    @close="closePopMansePrint()"
  />

  <!-- 공유하기 -->
  <PopShareBox
    v-if="isPopShareBox"
    :pr-data="rsData"
    @close="closePopShareBox()"
    @share="share"
  />

  <!--Alert창-->
  <AlertComponent
    ref="dialogAlert"
    :title="dialogTitle"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
</template>
