<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  prData: {
    type: Object,
    default: null,
  },
  prIndex: {
    type: Number,
    default: 0,
  },
  prGalleryDataTitle: {
    type: Array,
    default: [],
  },
  prBirthSkyTxt: {
    type: Array,
    default: [],
  },
  prBirthEarthTxt: {
    type: Array,
    default: [],
  },
  prGalleryTitles: {
    type: Array,
    default: [],
  },
})

const skyTxt = ref(props.prBirthSkyTxt)
const earthTxt = ref(props.prBirthEarthTxt)
const galleryTitles = ref(props.prGalleryTitles)

const isEmptyArray = (arrays) => {
  return arrays.every((arr) => arr.length === 0)
}

onMounted(() => {
  //dataSet(props.prDataAll); // 초기 데이터 설정
})

watch(
  () => props.prBirthSkyTxt,
  (newVal) => {
    skyTxt.value = newVal
  },
  { deep: true },
)

watch(
  () => props.prBirthEarthTxt,
  (newVal) => {
    earthTxt.value = newVal
  },
  { deep: true },
)
</script>
<template>
  <div class="data-detail-top">
    <span class="txt1" v-if="!isEmptyArray(prData[0])">원진</span>
    <span class="data-none" v-if="isEmptyArray(prData[0])"> 원진이 없습니다. </span>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[0]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="!isEmptyArray(prData[1])" class="gap-xxl"></div>
    <span class="txt1" v-if="!isEmptyArray(prData[1])">귀문</span>
    <span class="data-none" v-if="isEmptyArray(prData[1])"> 귀문이 없습니다. </span>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[1]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="!isEmptyArray(prData[2])" class="gap-xxl"></div>
    <span class="txt1">천간현침</span>
    <span class="data-none" v-if="isEmptyArray(prData[2])"> 천간현침이 없습니다. </span>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[2]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="!isEmptyArray(prData[3])" class="gap-xxl"></div>
    <span class="txt1">지지현침</span>
    <span class="data-none" v-if="isEmptyArray(prData[3])"> 지지현침이 없습니다. </span>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[3]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
